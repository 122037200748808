import React, {useState} from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, set, push } from "firebase/database";

function Write() {
    let [inputValue1, setInputValue1] = useState("");
    let [inputValue2, setInputValue2] = useState("");

    const saveData = async () => {
        const db = getDatabase(app);
        const newDocRef = push(ref(db, "subs"));
        set(newDocRef, {
            name: inputValue1,
            email: inputValue2
        }).then( () => {
            alert("data saved successfully")
        }).catch((error) => {
            alert("error: ", error.message);
        })
    }

    return (
        <div>
            <form>
            <div class="mb-3">
    <label for="exampleInputName1" class="form-label">Name</label><br/>
    <input type='text' value={inputValue1} 
            onChange={(e) => setInputValue1(e.target.value)}/>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Email address</label><br/>
    <input type='email' value={inputValue2} 
            onChange={(e) => setInputValue2(e.target.value)}/> <br/>
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>

  <button type="submit" class="btn btn-primary" onClick={saveData}>SAVE DATA</button>

</form>
        </div>
    )
}

export default Write